<template>
  <div class="tradeDetailcontainer">
      <Top></Top>
      <Fix></Fix>
      <div class="bread-box">
        <div class="bread-card">
          <el-breadcrumb class="bread-card-crumb" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{path:'/trade'}">商标服务</el-breadcrumb-item>
            <el-breadcrumb-item>{{this.title}}</el-breadcrumb-item>
          </el-breadcrumb>
         </div>
      </div>
      <div class="tradeDetail-box">
          <div class="tradeDetail-box-div">
            <div class="tradeDetail-box-div-des" >
              <div class="tradeDetail-box-div-des-div1">
                <p>{{this.click_title}}</p>
                <p>{{this.desc}}</p>
              </div>
              <div class="tradeDetail-box-div-des-div2">
                <span>服务价格：</span>
                <span>￥{{price}}</span>
              </div>
              <div class="tradeDetail-box-div-des-div3">
                <div>服务类型：</div>
                <div class="tradeDetail-box-div-des-div3-type">
                  <div v-for="(v,k) in type" :key="k" @click="changeType(v.goodsName,v.price,v.goodsDescribe,v.webBusinessProcess,v.imgBottom,v.goodsCode)" v-bind:class="{colorChanged:v.goodsName==dynamic}">{{v.goodsName}}</div>
                </div>
              </div>
              <div style="clear:both;"></div>
              <div class="tradeDetail-box-div-des-div4" v-if="this.title != '商标注册'">
                <div>购买数量：</div>
                <div @click="miu1">-</div>
                <div>
                  <input v-model="value1" type="number" disabled >
                </div>
                <div @click="add1">+</div>
              </div>
              <div class="tradeDetail-box-div-des-div2" v-if="this.title != '商标注册'">
                <span>结算金额：</span>
                <span>￥{{total}}</span>
              </div>
              <div class="trade-reg-div" v-if="this.title == '商标注册'">
                  <span id="reg-span1" v-show="this.click_title == '顾问商标注册'&&this.u_type != '3'" @click="ask">立即咨询</span>
                  <span v-show="this.u_type != '3'" @click="tradeDo">立即办理</span>
                  <span v-show="this.click_title == 'AI商标注册'&&this.u_type == '3'">立即办理</span>
                  <span v-show="this.click_title == '顾问商标注册'&&this.u_type == '3'">代办理</span>
              </div>
            </div>
            <img class="tradeDetail-box-img" :src="this.pic" alt="">
            <div style="clear:both;"></div>
            <div class="tradeDetail-box-div-des-div5" v-if="this.title != '商标注册'">
              <div @click="tobuy">立即购买</div>
              <div @click="addCart">加入购物车</div>
            </div>
          </div>
      </div>
      <div class="banner">
        <img :src="this.bigimg" alt="">
      </div>
    <Foot></Foot>
    <div class="trade-window" v-show="askShow">
      <div class="trade-little-window">
        <img :src="require('/static/images/orderdetailcha.png')" alt="" @click="cha1">
        <div class="trade-little-windowbox">
          <div>
            <img :src="require('/static/images/trade-weixin.png')" alt="">
            <span>{{consultant.wxAccount}}</span>
            <span :data-clipboard-text="consultant.wxAccount" @click="copy(2)" id="specil-span">复制</span>
          </div>
          <div>
            <img :src="require('/static/images/trade-phone.png')" alt="">
            <span>{{consultant.empMobile}}</span>
            <span :data-clipboard-text="consultant.empMobile" @click="copy(1)" id="specil-span">复制</span>
          </div>
        </div>
      </div>
    </div>
    <div class="trade-link" v-show="linkShow">
      <div class="trade-link-window">
        <img :src="require('/static/images/orderdetailcha.png')" alt="" @click="cha2">
        <div class="trade-link-box">
            <div>
              <p>您的姓名</p>
              <input type="text" placeholder="请告诉我如何称呼您" v-model="linkName">
            </div>
            <div class="trade-msg">{{linkNameTip}}</div>
            <div>
              <p>您的电话号码</p>
              <input type="number" placeholder="请留下可以与您联系的手机号" v-model="linkPhone">
            </div>
            <div class="trade-msg">{{linkPhoneTip}}</div>
            <div class="trade-link-submit" @click="tradeSubmit">提交</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Clipboard from 'clipboard';
import Top from '../ui/top.vue';
import Foot from '../ui/foot.vue';
import Fix from '../ui/fix.vue';
export default {
  name: 'tradeDetail',
  data(){
      return{
          value1:1,
          price:'',
          total:'',
          title:'',
          desc:'',
          pic:'',
          type:[],
          dynamic:'',
          click_title:'',
          bigimg:'',
          u_type:'',
          askShow:false,
          linkShow:false,
          consultant:{},
          linkName:'',
          linkNameTip:'',
          linkPhone:'',
          linkPhoneTip:'',
          code:'',
          // 添加购物车的数据
          cartListObj: {
            userCode: '',
            //商品code
            goodsCode: '',
            //商品名字
            goodsName: '',
            //商品价格
            orderPrice: ''
        },
        personInfo:{},
        orderType:''
      }
  },
  components: {
      Top,
      Foot,
      Fix
  },
  watch:{
    '$route': 'getdata',
    "linkName"(newValue){
      if(newValue ==''){
          return this.linkNameTip='请填写您的姓名';
        }
        return this.linkNameTip='';
    },
    "linkPhone"(newValue){
          let reg=/^[1][3,4,5,6,7,8,9][0-9]{9}$/;
          if(newValue ==''){
              return this.linkPhoneTip='请填写手机号码';
          }
          if(newValue.length!=11||!reg.test(newValue)){
              return this.linkPhoneTip='手机号格式不正确';
          }
          return this.linkPhoneTip='';
      },
  },
  methods:{
    tip(type,msg){
          return this.$message({
              type:type,
              message:msg,
              showClose:true,
              duration:1500
          })
      },
     
      getdata(){
          this.$http.post(this.GLOBAL.new_url+'/goods/service/goods/queryGoodsList',{
            goodsLevel:'0',
            userCode:this.$cookie.getCookie('u_id'),
            goodsType:'1'
          }).then(res=>{
            // console.log(res)
                if(res.data.code == "200"){
                    var arr = [];
                    arr = res.data.data;
                    const agent = window.localStorage.getItem('UserAgent')
                    for(var i = 0;i<arr.length;i++){
                      for(var j =0;j<arr[i].goodsInfoDTOList.length;j++){
                        var _this=this;
                         if(agent == 'HT'){
                          _this.$set(arr[i].goodsInfoDTOList[j], 'price', Number(arr[i].goodsInfoDTOList[j].proxyPrice)+100);
                         }else{
                           _this.$set(arr[i].goodsInfoDTOList[j], 'price', Number(arr[i].goodsInfoDTOList[j].proxyPrice));
                         }
                      }
                      }
                      if(this.$route.query.secondName == "0"){
                        this.title= this.$route.query.parentName;
                        for(var i = 0;i<arr.length;i++){
                            if(this.$route.query.parentName == arr[i].goodsName){
                              this.type = arr[i].goodsInfoDTOList;
                              this.click_title = this.type[0].goodsName;
                              this.desc = this.type[0].goodsDescribe;
                              this.price = this.type[0].price;
                              this.total = this.price*this.value1;
                              this.dynamic = this.type[0].goodsName;
                              this.bigimg = this.type[0].webBusinessProcess;
                              this.pic = this.type[0].imgBottom;
                              this.code = this.type[0].goodsCode;
                            }
                        }
                      }else{
                          for(var i=0;i<arr.length;i++){
                            for(var j =0;j<arr[i].goodsInfoDTOList.length;j++){
                              if(this.$route.query.secondName== arr[i].goodsInfoDTOList[j].goodsName ){
                                this.type = arr[i].goodsInfoDTOList;
                                this.title = arr[i].goodsName;
                                this.click_title = arr[i].goodsInfoDTOList[j].goodsName;
                                this.desc = arr[i].goodsInfoDTOList[j].goodsDescribe;
                                this.price = arr[i].goodsInfoDTOList[j].price;
                                this.total = this.price*this.value1;
                                this.dynamic = arr[i].goodsInfoDTOList[j].goodsName;
                                this.bigimg = arr[i].goodsInfoDTOList[j].webBusinessProcess;
                                this.pic = arr[i].goodsInfoDTOList[j].imgBottom;
                                this.code = arr[i].goodsInfoDTOList[j].goodsCode;
                              }
                            }
                          }
                      }
                }
                
            })
      },
      changeType(val1,val2,val3,val4,val5,val6){
        this.dynamic = val1;
        this.click_title = val1;
        this.price = val2;
        this.total = this.price*this.value1;
        this.desc = val3;
        this.bigimg = val4;
        this.pic = val5;
        this.code = val6;
      },
      tradeDo(){
        if(this.$cookie.getCookie('u_id')){
          if(this.click_title == '顾问商标注册'){
            this.linkShow = true
          }else{
            this.$router.push({
              path:'/recome',
              query:{
                goodsName:this.click_title
              }
            })
          }
        }else{
          this.$router.push({
            path:'/login',
            query:{
              good:0
            }
          })
        }
      },
      ask(){
        if(this.$cookie.getCookie('u_id')){
          this.askShow = true
        }else{
          this.$router.push({
            path:'/login',
            query:{
              good:0
            }
          })
        }
      },
      cha1(){
        this.askShow=false
      },
      cha2(){
        this.linkShow = false
      },
      tradeSubmit(){
        if(this.$cookie.getCookie('u_id')){
          if(this.linkName == ''){
            this.linkNameTip = '请填写您的姓名';
            return false
          }else if(this.linkPhone == ''||this.linkPhoneTip=='手机号格式不正确'){
            this.linkPhoneTip = '请填写您的手机号';
            return false
          }else{
            this.$http.post(this.GLOBAL.new_url+'/user/service/insertConsultData',{
            userCode:this.$cookie.getCookie('u_id'),
            userMobile:this.linkPhone,
            empCode:this.consultant.empCode,
            adviseNickName:this.consultant.adviseNickName,
            userCall:this.linkName,
            consultType:2
          },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
              // console.log(res)                    
              if(res.data.code == "200"){
                this.linkShow = false;
                this.tip('success','提交成功')
              }else{
                this.tip('error',res.data.msg)
              }
          })
            
          }
        }else{
          this.$router.push({
            path:'/login',
            query:{
              good:0
            }
          })
        }
      },
      // 复制
      copy(val) {
      var clipboard = new Clipboard('#specil-span')
      clipboard.on('success', e => {
          this.$http.post(this.GLOBAL.new_url+'/user/service/insertConsultData',{
                 userCode:this.$cookie.getCookie('u_id'),
                  userName:this.personInfo.userName,
                  userMobile:this.personInfo.userMobile,
                  empCode:this.personInfo.empCode,
                  adviseNickName:this.consultant.empName,
                  userCall:this.personInfo.userName,
                  consultType:1,
                  consultOper:val
              },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                  if(res.data.code == "200"){
                    this.tip('success','复制成功');
                  }else{
                    this.tip('error',res.data.msg)
                  }
              })
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', e => {
      // 不支持复制
      console.log('该浏览器不支持自动复制')
      // 释放内存
      clipboard.destroy()
      })
      },
      addCart(){
        if(this.$cookie.getCookie('u_id')){
            if(this.value1>=1){
                var list = [];
                this.cartListObj.userCode = this.$cookie.getCookie('u_id');
                this.cartListObj.goodsName = this.click_title;
                this.cartListObj.goodsCode = this.code;
                this.cartListObj.orderPrice = this.price;
                 for (let i = 0; i < this.value1; i++) {
                  list.push(this.cartListObj)
                };
                this.$http.post(this.GLOBAL.new_url+'/trademark/service/cart/addCart',{
                  cartType:'1',
                  cartVOList:list,
                  typeName:this.title
              },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                  if(res.data.code == "200"){
                    this.tip('success',"添加购物车成功");
                  }else{
                    this.tip('error',res.data.msg)
                  }
              })
              // console.log(list)
            }
            
        }else{
          this.$router.push({
            path:'/login',
            query:{
              good:0
            }
          })
        }

      },
      add1(){
          this.value1++;
            this.total = this.price*this.value1;
      },
      miu1(){
        if(this.value1>1){
          this.value1--;
            this.total = this.price*this.value1;
        }
      },
      tobuy(){
        if(this.$cookie.getCookie('u_id')){
          var list = [];
      var detail = {
        goodsType: "1",
        goodsCode: this.code,
			goodsName: this.title,
			orderType: this.click_title,
			serviceTypeName: this.click_title
		}
            if(this.value1>=1){
              for (let i = 0; i < this.value1; i++) {
                        list.push(detail)
                      };
            }
            // console.log(list)
            this.$http.post(this.GLOBAL.new_url+'/transactionv2/webapi/insertOrder',{
            list:list
          },{
                    headers:{
                      'X-User-Code':this.$cookie.getCookie('u_id'),
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
            // console.log(res)
              if(res.data.code == "200"){
                  var a = '';
                  for(var i =0;i<res.data.data.length;i++){
                            a+=res.data.data[i].orderCode+','
                    }
                    a =  a.substring(0,a.length-1);
                  this.$router.push({
                      path:'/checkOrder',
                      query:{
                      order:a
                      }
                  })
              }else{
                this.tip('error',res.data.msg)
              }
          })
        }else{
          this.$router.push({
            path:'/login',
            query:{
              good:0
            }
          })
        }

      }
  },
  mounted(){
      this.getdata();
      if(this.$cookie.getCookie('u_id')){
        this.$http.post(this.GLOBAL.new_url+'/user/service/getAdviserList',{
                        userCode:this.$cookie.getCookie('u_id'),
                        allAdvise:"1"
                    },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                        if(res.data.code == "200"){
                            for(var i=0;i<res.data.data.length;i++){
                                if(this.$cookie.getCookie('consultant') == res.data.data[i].empCode){
                                    this.consultant = res.data.data[i]
                                    // console.log(this.consultant)
                                }
                            }
                             this.$http.post(this.GLOBAL.new_url+'/user/service/getPersonInfo',{
                                userCode:this.$cookie.getCookie('u_id')
                              },{
                                            headers:{
                                                AuthToken:this.$cookie.getCookie('token'),
                                                'X-Request-Origin':'xcx'
                                            }
                                                        }).then(res=>{
                              // console.log(res)
                              if(res.data.code== "200"){
                                this.personInfo = res.data.data;
                              }

                            })
                        }
              
                    })
      }
  }
}

</script>

<style scoped>
  .bread-box{
    width: 100%;
    height: 40px;
    background-color: #f8f8f8;
  }
  .bread-card{
    width: 1200px;
    height: 28px;
    margin: 0 auto;
    padding-top:12px;
  }
  .tradeDetail-box-div{
    width: 1200px;
    margin: 0 auto;
  }
  .tradeDetail-box-div-des{
    float: left;
  }
  .tradeDetail-box-div-des-div1{
    height: 54px;
    margin: 21px 0 34px;
  }
  .tradeDetail-box-div-des-div1>p:nth-of-type(1){
    font-family: "PingFangSC-Medium";
    font-size: 22px;
    font-weight: 500;
    color: #333333;
    margin-bottom: 4px;
  }
  .tradeDetail-box-div-des-div1>p:nth-of-type(2){
    font-family: "PingFangSC-Regular";
    font-size: 14px;
    color: #5b5b5b;
  }
  .tradeDetail-box-div-des-div2{
    font-family: "PingFangSC-Regular";
    font-size: 18px;
    margin-bottom: 14px;
  }
  .tradeDetail-box-div-des-div2>span:nth-of-type(1){
    color: #434343;
  }
  .tradeDetail-box-div-des-div2>span:nth-of-type(2){
    color: #ff1d1d;;
  }
  .tradeDetail-box-div-des-div3{
    /* height: 120px; */
    margin-bottom: 14px;
  }
  .tradeDetail-box-div-des-div3>div{
    float: left;
  }
  .tradeDetail-box-div-des-div3>div:nth-of-type(1){
    font-family: "PingFangSC-Regular";
    font-size: 18px;
    margin-top: 4px;
    color: #434343;
  }
  .tradeDetail-box-div-des-div3-type{
    width: 480px;
    margin-left: 4px;
  }
  .tradeDetail-box-div-des-div3-type>div{
    float: left;
    height: 33px;
    line-height: 33px;
    text-align: center;
    font-size: 18px;
    color: #ffffff;
    padding:0 20px;
    margin-right: 12px;
    margin-bottom: 20px;
    border-radius: 4px;
    background-color: #cdcdcd;
    cursor: pointer;
  }
  .tradeDetail-box-div-des-div3-type .colorChanged{
    background-color: #EB5E00;
  }
  .tradeDetail-box-div-des-div4{
    margin-bottom: 14px;
    height: 26px;
  }
  .tradeDetail-box-div-des-div4>div{
    float: left;
  }
  .tradeDetail-box-div-des-div4>div:nth-of-type(1){
    font-family: "PingFangSC-Regular";
    font-size: 18px;
    color: #434343;
  }
  .tradeDetail-box-div-des-div4>div:nth-of-type(2),.tradeDetail-box-div-des-div4>div:nth-of-type(4){
    font-family: "PingFangSC-Regular";
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    cursor: pointer;
    line-height: 26px;
    width: 26px;
    height: 26px;
    border-radius: 2px;
    background-color: #f1f1f1;
    color: #434343;
  }
  .tradeDetail-box-div-des-div4>div:nth-of-type(2){
    margin-left: 4px;
  }
  .tradeDetail-box-div-des-div4>div>input{
    width: 44px;
    height: 22px;
    outline: none;
    border:none;
    text-align: center;
    padding: 0 5px;
    vertical-align: middle;
  }
  .trade-reg-div{
    margin-top: 63px;
  }
  .trade-reg-div>span{
    display: inline-block;
    width: 120px;
    height: 41px;
    text-align: center;
    line-height: 41px;
    border-radius: 4px;
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
    margin-right: 22px;
    background: #F96009;
    cursor: pointer;
  }
  #reg-span1{
    background: #F79002;
  }
  .tradeDetail-box-img{
    width: 560px;
    height: 360px;
    float: right;
    margin-top: 21px;
  }
  .tradeDetail-box-div-des-div5{
    /* margin-top: 54px; */
    height: 41px;
  }
  .tradeDetail-box-div-des-div5>div{
    width: 120px;
    height: 41px;
    border-radius: 4px;
    float: left;
    text-align: center;
    line-height: 41px;
    font-family: "PingFangSC-Regular";
    font-size: 18px;
    cursor: pointer;
  }
  .tradeDetail-box-div-des-div5>div:nth-of-type(1){
    background-color: #f79000;
    color: #ffffff;
    margin-right: 41px;
  }
  .tradeDetail-box-div-des-div5>div:nth-of-type(2){
    background-color: #e3e3e3;
    color: #f87604;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }
  input{
    -moz-appearance:textfield;
  }
  .banner {
    width: 1200px;
    margin: 40px auto 0;
}
.banner img {
    width: 1920px;
    margin: 0 -420px;
    vertical-align: middle;
}
.trade-window,.trade-link{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
    z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
}
.trade-little-window{
  width: 430px;
  height: 272px;
  background-color: #ffffff;
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%)
}
.trade-little-window>img,.trade-link-window>img{
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.trade-little-windowbox{
  position: absolute;
  top:70px;
  left:44px;
}
.trade-little-windowbox>div{
  width: 342px;
  height: 56px;
  background: #F5F5F5;
  border-radius: 28px;
}
.trade-little-windowbox>div>span{
  vertical-align: middle;
  font-size: 18px;
}
.trade-little-windowbox>div>span:nth-of-type(1){
  display: inline-block;
  width: 117px;
  overflow: hidden;    
  text-overflow:ellipsis;    
  white-space: nowrap; 
}
.trade-little-windowbox>div:nth-of-type(1){
  margin-bottom: 30px;
}
.trade-little-windowbox>div>img{
  vertical-align: middle;
  margin: 0 13px 0 19px;
}
#specil-span{
  display: inline-block;
  width: 82px;
  height: 40px;
  font-size: 14px;
  text-align: center;
  line-height: 40px;
  background: #FFFFFF;
  border-radius: 20px;
  margin-left: 30px;
  cursor: pointer;
}
.trade-link-window{
  width: 430px;
  height: 312px;
  background-color: #ffffff;
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%)
}
.trade-link-box{
  position: absolute;
  top: 50px;
  left: 40px;
}
.trade-msg{
  height: 30px;
  color: red;
  line-height: 30px;
  margin-left: 14px;
  font-size: 14px;
}
.trade-link-box>div>p{
  font-size: 16px;
  margin-bottom: 12px;
  font-weight: 400;
}
.trade-link-box>div>input{
  outline: none;
  width: 335px;
  height: 34px;
  padding-left: 15px;
  border-radius: 3px;
  border: 1px solid #D8D8D8;
  font-size: 14px;
  font-weight: 400;
}
.trade-link-submit{
  width: 108px;
  height: 36px;
  background: #F96009;
  border-radius: 4px;
  line-height: 36px;
  cursor: pointer;
  margin: 0 auto;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
}
</style>
<style>
  .el-breadcrumb__inner a, .el-breadcrumb__inner.is-link{
      font-weight: normal;
  }
  .el-breadcrumb__inner a, .el-breadcrumb__inner.is-link:hover{
      color:#303133;
  }
</style>

